<template>
  <div
    class="event"
    v-if="event"
    :class="[{ event_large: large }, event.state ? event.state : '']"
    @click="toEvent()"
  >
    <div class="event__preview">
      <div
        class="event__tags"
        :class="{ event__tags_absolute: formattedImageList.length }"
        v-if="event.nosology.length || event.type"
      >
        <div class="event__tag-wrap">
          <div
            class="event__tag"
            v-for="tag in event.nosology"
            :key="`nos_${tag.id}`"
          >
            {{ tag.title }}
          </div>
          <div
            class="event__tag"
            v-for="type in event.types"
            :key="`type_${type.id}`"
          >
            {{ type.name }}
          </div>
           <div
            class="event__tag" v-if="isOnline"
          >
            <span class="broadcast"></span>В эфире
          </div>
        </div>

        <!-- <div class="event__tag-wrap" v-if="event.types && event.types.length">
        <div class="event__tag" v-for="type in event.types" :key="type.id">
          {{ type.name }}
        </div>
      </div> -->
      </div>
      <picture v-if="formattedImageList.length">
        <source
          v-for="(img, ind) in formattedImageList"
          :key="ind"
          :srcset="img.src"
          :media="img.media"
        />
        <img
          class="event__image"
          v-if="large"
          :src="formattedImageList[formattedImageList.length - 1].src"
        />
      </picture>
    </div>

    <!-- <div v-if="!event.large" class="event__caption">{{ event.name }}</div> -->
    <div v-if="!event.hasOwnProperty('state')">
      <div v-if="!large" class="event__caption">
        {{ event.name }}
      </div>
      <router-link
        v-if="!event.external && large"
        :to="{ name: 'EventDetail', params: { id: event.id } }"
        class="event__caption"
      >
        {{ event.name }}
      </router-link>
      <a
        v-else-if="event.external && large"
        target="_blank"
        :href="event.external_link"
        class="event__caption"
      >
        {{ event.name }}
      </a>
    </div>
    <div v-else class="event__caption">
      {{ event.name }}
    </div>

    <div
      class="event__description"
      v-if="event.description && large"
      v-html="event.description"
    ></div>
    <div class="event__date">{{ isToday }}</div>
    <div class="event__buttons" v-if="large && !event.hasOwnProperty('state')">
      <div
        v-if="large"
        @click="detailButton()"
        class="event__detail button button_pink"
      >
        Подробнее
      </div>
      <div
        class="event__add-calendar button button_empty-pink"
        @click="getCalendar(event.id)"
      >
        Добавить в календарь (.ics)
        <!-- <span class="ml-2">
          <svg
            width="18"
            height="10"
            viewBox="0 0 18 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.25 1.25024L8.75 8.75024L1.25 1.25024"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="square"
            />
          </svg>
        </span> -->
      </div>
    </div>
    <div v-else-if="event.hasOwnProperty('state')" class="event__passed">
      Мероприятие завершено
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Event",
  props: {
    event: Object,
    large: Boolean,
  },
  data: () => ({
    media: {
      mobile: 767,
      tablet: 1220,
      desktop: 1919,
      desktop_l: 2559,
      desktop_xl: 3839,
      desktop_xxl: 9999999,
    },
  }),
  computed: {
    isToday() {
      let date_start = this.$m(new Date(this.event.date)).format(
        "DD MMMM YYYY"
      );
      let date_end = this.$m(new Date(this.event.date_end)).format(
        "DD MMMM YYYY"
      );
      const today = new Date();
      let noTime = false;
      if (
        new Date(this.event.date).getDate() ==
          new Date(this.event.date_end).getDate() &&
        new Date(this.event.date).getMonth() ==
          new Date(this.event.date_end).getMonth() &&
        new Date(this.event.date).getFullYear() ==
          new Date(this.event.date_end).getFullYear()
      ) {
        date_end = "";
      } else {
        noTime = true;
      }
      if (
        new Date(this.event.date).getDate() == today.getDate() &&
        new Date(this.event.date).getMonth() == today.getMonth() &&
        new Date(this.event.date).getFullYear() == today.getFullYear()
      ) {
        date_start = "Сегодня";
      }
      if (noTime) {
        return `${date_start}  - ${date_end}`;
      } else {
        if (
          new Date(this.event.date).getTime() ===
          new Date(this.event.date_end).getTime()
        ) {
          return `${date_start} ${this.$m
            .utc(this.event.date)
            .utcOffset(+3)
            .format("HH:mm")} (Мск)`;
        } else {
          return `${date_start} ${this.$m
            .utc(this.event.date)
            .utcOffset(+3)
            .format("HH:mm")} - ${date_end} ${this.$m
            .utc(this.event.date_end)
            .utcOffset(+3)
            .format("HH:mm")} (Мск)`;
        }
      }
    },
    isOnline() {
      const today = this.$m
            .utc(new Date())
            .utcOffset(+3).toDate();
      const date_start = new Date(this.event.date)
      const date_end = new Date(this.event.date_end)
      if(this.event.types.find((el) => el.code === 'online') && today.getTime() > date_start.getTime() && today.getTime() < date_end.getTime()) {
        return true
      }
      return false
    },
    formattedImageList() {
      let imageList = [];
      let usedImages = Object.keys(this.event)
        .filter((el) => el.includes("preview_"))
        .map((el) => el.replace("preview_", ""));
      for (let imgFormat of usedImages) {
        if (
          this.event["preview_" + imgFormat] &&
          Object.keys(this.media).includes(imgFormat)
        ) {
          imageList.push({
            src: this.event["preview_" + imgFormat].replace("http:", "https:"),
            media: `(max-width: ${this.media[imgFormat]}px)`,
          });
        }
      }
      imageList.sort((a, b) => (a.media > b.media ? 1 : -1));
      return imageList;
    },
  },
  methods: {
    ...mapActions(["fetchEventsCalendar"]),

    async getCalendar(id) {
      const url = await this.fetchEventsCalendar(id);
      let a = document.createElement("a");
      a.style.display = "none";
      document.body.appendChild(a);
      a.href = url;
      a.download = "event.ics";
      a.click();
      setTimeout(async () => {
        window.URL.revokeObjectURL(url);
      }, 1000);
      a.remove();
    },
    toEvent() {
      if (!this.large && !this.event.hasOwnProperty("state")) {
        if (this.event.external) {
          window.open(this.event.external_link);
        } else {
          this.$router
            .push({
              name: "EventDetail",
              params: { id: this.event.id },
            })
            .catch(() => {});
        }
      }
    },
    detailButton(to) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "events page event detail click", {
          "events page event detail click": {
            "event name": this.event.name,
            ...this.$root.ymFields,
          },
        });
      }
      if (this.event.external) {
        window.open(this.event.external_link);
      } else {
        this.$router
          .push({
            name: "EventDetail",
            params: { id: this.event.id },
          })
          .catch(() => {});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.broadcast {
  display: inline-block;
  transform: translateY(-1px);
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: red;
  margin-right: 4px;
  animation: blink 1s linear infinite alternate;
}
@keyframes blink {
  from {
    opacity: 0;
  } 
  to {
    opacity: 1;
  }
}
.event {
  padding: 16px;
  border: 1px solid #ebefee;

  &:last-child {
    margin-bottom: 0 !important;
  }

  &:not(.event_large):not(.passed):hover .event__caption {
    color: #d0006f;
  }

  &__tags {
    position: relative;
    z-index: 2;
    margin-bottom: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__tag {
    margin-bottom: 4px;
    margin-right: 4px;
    padding: 4px 8px;
    display: inline-block;
    border: 1px solid #d2d2d2;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 13px;
    color: #656969;
    border-radius: 999px;
    background-color: #fff;
  }

  &__tag-wrap {
    // margin-right: 4px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__caption {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #1f1f1f;
    transition: 0.3s;
  }

  &__description {
    margin-top: 8px;
    margin-bottom: 16px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #656969;
    @media screen and (max-width: 767px) {
      font-size: 12px;
      line-height: 14px;
    }
  }

  &__passed {
    margin-top: 16px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #830051;
  }

  &__date {
    margin-top: 12px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: #8b8e8e;
  }

  &__buttons {
    margin-top: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      margin-top: 16px;
    }
  }

  &__detail {
    margin-right: 16px;
    width: 136px;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 12px;
    }
  }

  &__add-calendar {
    width: 311px;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}

.event_large {
  padding: 32px;
  cursor: initial;
  @media screen and (max-width: 767px) {
    padding: 16px;
  }

  .event {
    &__tags_absolute {
      position: absolute;
      top: 0;
      left: 0;
    }
    &__preview {
      padding-top: 1px;
      position: relative;
      margin: -32px -32px 12px;

      @media screen and (max-width: 767px) {
        margin: -16px -16px 12px;
      }

      & .event__tags {
        margin: 32px 0 12px 32px;

        @media screen and (max-width: 767px) {
            margin: 16px 0 12px 16px;
        }
      }
    }

    &__image {
      width: 100%;
      height: 148px;
      object-fit: cover;
      object-position: top;
    }

    &__caption {
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
    }

    &__passed {
      font-size: 18px;
      line-height: 28px;
    }

    &__date {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #830051;
    }
  }
}
.event:not(.passed) {
  cursor: pointer;
  .event__caption {
    &:hover {
      color: #d0006f;
    }
  }
}
.event.passed {
  .event__date {
    color: #cc99b9;
  }
}
</style>